import { createStore } from 'vuex'

export default createStore({
  state: {
    liffId: '1656804955-jmZzqoV0',
    isLoggedIn: false,
    context: {},
    profile: {},
    redirectAfterLogin: 'map',
  },
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setContext(state, context) {
      state.context = context;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setRedirect(state, redirect) {
      state.redirectAfterLogin = redirect;
    },
  },
})
