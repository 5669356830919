import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faStar, faStarSharp, faXmark, faDiamondTurnRight, faStreetView } from '@fortawesome/pro-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons'
import { faCircleExclamation, faLocationCrosshairs } from '@fortawesome/pro-duotone-svg-icons'
import router from './router'
import store from './store'
import VueLuckyCanvas from '@lucky-canvas/vue'


library.add(
    faStar, 
    faStarSharp, 
    farStar, 
    faXmark, 
    faDiamondTurnRight,
    faCircleExclamation,
    faLocationCrosshairs,
    faStreetView,
)

createApp(App)
    .use(store)
    .use(router)
    .use(VueLuckyCanvas)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
