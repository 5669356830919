import { createRouter, createWebHistory } from 'vue-router'
import tickets from "@/views/tickets";

const routes = [
  {
    path: '/',
    component: tickets,
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue')
  },
  {
    path: '/permission',
    name: 'permission',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "permission" */ '../views/permission.vue')
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../views/tickets.vue')
  },
  {
    path: '/chatroom',
    name: 'chatroom',
    component: () => import(/* webpackChunkName: "chatroom" */ '../views/chatroom.vue')
  },
  {
    path: '/store/:storeId',
    name: 'storeMenu',
    component: () => import(/* webpackChunkName: "storeMenu" */ '../views/menu.vue')
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: () => import(/* webpackChunkName: "storeMenu" */ '../views/voucher.vue')
  },
  {
    path: '/game',
    name: "game",
    component: () => import(/* webpackChunkName: "game" */ '../views/game.vue')
  },
  {
    path: '/redeem/:from',
    name: "redeem",
    component: () => import(/* webpackChunkName: "redeem" */ '../views/redeem.vue')
  },
  {
    path: '/chat',
    name: "chat",
    component: () => import(/* webpackChunkName: "chat" */ '../views/chat.vue')
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import(/* webpackChunkName: "rank" */ '../views/rank.vue')
  },
  {
    path: '/team',
    name: "team",
    component: () => import(/* webpackChunkName: "team" */ '../views/team.vue')
  },
  {
    path: '/lottery',
    name: "lottery",
    component: () => import(/* webpackChunkName: "lottery" */ '../views/lottery.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
