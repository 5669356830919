<template>
  <div class="w-screen h-full min-h-screen overflow-hidden top-0 bg-black bg-ticket-primary">
    <div class="w-full">
      <img
          alt="eventLogo"
          class="w-5/6 mx-auto my-6 top-0 text-center"
          :src="eventSrc"
      />
    </div>
    <div class="w-full flex items-center grid grid-cols-5 my-14 gap-1 px-2">
      <img
        :src="ticketLeftSrc"
        class="col-span-1"
      />
      <vue-agile class="bg-white h-full rounded-2xl col-span-3 border border-white" :autoplay="true" :autoplay-speed="1500" :navButtons="false" :fade="true">
        <img
            v-for="(slide, index) in sliders"
            :key="index"
            class="slider rounded-2xl"
            :src="slide"
        />
      </vue-agile>
      <img
          :src="ticketRightSrc"
          class="col-span-1"
      />
    </div>
    <div class="w-full flex items-center justify-center">
      <button class="bg-[#e8bc5d] px-4 py-1 rounded-lg mx-2 w-1/3 text-lg" @click="goVoucher" v-show="me.tickets > 0">酒品兌換券</button>
      <button class="bg-[#e8bc5d] px-4 py-1 rounded-lg mx-2 w-1/3 text-lg" @click="shareTicket" v-show="me.canSharedTickets > 0">分享票券</button>
      <button class="bg-[#e8bc5d] px-4 py-1 rounded-lg mx-2 w-1/3 text-lg" @click="goOfficialWebsite" v-show="me.tickets === 0">前往購票</button>
    </div>
    <img
      alt="noticeBanner"
      class="w-full bottom-0 fixed bg-black px-2 py-1"
      :src="noticeSrc"
    />
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import liff from '@line/liff'
import { detect } from "detect-browser";
import {detectIncognito} from "detect-incognito";
import axios from "axios";
import flexMessage from "@/assets/shareTicket.json"

export default {
  name: "ticketsPage",
  data(){
    return {
      noticeSrc: require("@/assets/notice.png"),
      eventSrc: require("@/assets/event.png"),
      halfwayStudioSrc: require("@/assets/halfway_studio.png"),
      ticketLeftSrc: require("@/assets/ticket_left.png"),
      ticketRightSrc: require("@/assets/ticket_right.png"),
      sliders: [
        require("@/assets/sliders/main.jpg"),
        require("@/assets/sliders/slide1.jpg"),
      ],
      incognito: false,
      isLoggedIn: false,
      me: {
        tickets: -1,
        canSharedTickets: 0,
      },
      flexMessage: flexMessage,
    }
  },
  methods: {
    liffInit() {
      if (!liff.isLoggedIn()){
        if (liff.isInClient()){
          liff.login()
        }else{
          let browser = detect();
          if (browser.name === 'instagram') {
            alert("請使用外部瀏覽器，以獲得更好的體驗！");
            return;
          }
          // detect browser in private mode
          if (this.incognito) {
            alert("請關閉無痕模式，以獲得更好的體驗！");
            return;
          }
          liff.login();
        }
      }else{
        this.isLoggedIn = true;
        this.getMe()
      }
    },
    liffError(error) {
      console.log(error)
    },
    getMe(){
      axios
          .get(`https://backend.hwstud.io/api/me`, {
            headers: {
              Authorization: `Bearer ${liff.getContext()["userId"]}`
            }
          })
          .then(({ data }) => {
            this.me = data;
          })
          .catch(() => {
            this.me.tickets = 0;
          })
    },
    goVoucher(){
      this.$router.push({
        name: "voucher"
      })
    },
    goOfficialWebsite(){
      window.open('https://event.hwstud.io')
    },
    shareTicket(){
      this.flexMessage["contents"]["body"]["contents"][2]["contents"][0]["action"]["uri"] = `https://liff.line.me/1656804955-jmZzqoV0/redeem/${liff.getContext()["userId"]}`
      console.log(`https://liff.line.me/1656804955-jmZzqoV0/redeem?from=${liff.getContext()["userId"]}`)
      liff.shareTargetPicker([this.flexMessage], {
        isMultiple: false,
      })
        .then((res) => {
          if (res){
            console.log("Message Sent!")
          }
        })
        .catch(() => {
          console.log("Message Send Error")
        })
    }
  },
  components: {
    VueAgile,
  },
  mounted(){
    detectIncognito().then(result => {
      this.incognito = result.isPrivate;
    })
    liff.init({
      liffId: "1656804955-jmZzqoV0",
    }, this.liffInit, this.liffError)
  }
}
</script>